import { XF } from "./XF";

function noop() {
  // intentionally left blank
}

// Deal with the console not being present
if (!window.console) window.console = {} as (typeof window)["console"];
if (window.console.log && !window.console.debug)
  window.console.debug = window.console.log;
(
  [
    "assert",
    "clear",
    "count",
    "debug",
    "dir",
    "dirxml",
    "error",
    "getFirebugElement",
    "group",
    "groupCollapsed",
    "groupEnd",
    "info",
    "log",
    "notifyFirebug",
    "profile",
    "profileEnd",
    "time",
    "timeEnd",
    "trace",
    "warn",
  ] as const
).forEach((fn) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.console[fn]) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.console[fn] = noop;
});

// IMPORTANT - jQuery is not available here!
// Keep this code minimal and only for things that need to happen in the <head> tag.

const docEl = document.documentElement;
docEl.addEventListener(
  "error",
  (e) => {
    const { target } = e;
    const onerror = target.getAttribute("data-onerror");
    switch (onerror) {
      case "hide":
        target.style.display = "none";
        break;

      case "hide-parent":
        target.parentNode.style.display = "none";
        break;

      default:
        break;
    }
  },
  true
);

type StorageKey =
  | "supportTouch"
  | "supportPassiveEventListeners"
  | "supportHiddenscroll"
  | "browserOriginal";
const storageInMemory: Partial<Record<StorageKey, string>> = {};
let storageAvailable: boolean | undefined;
function testStorage() {
  if (storageAvailable !== undefined) return storageAvailable;
  try {
    const test = "storage-test";
    window.localStorage.setItem(test, test);
    if (window.localStorage.getItem(test) !== test)
      throw new Error("storage not available");
    window.localStorage.removeItem(test);
    storageAvailable = false;
  } catch (err) {
    console.error(err);
    storageAvailable = false;
  }
  return storageAvailable;
}
function setItemInLocal(key: StorageKey, value: string) {
  storageInMemory[key] = value;
  if (testStorage()) window.localStorage.setItem(key, value);
}
function getItemFromLocal(key: StorageKey) {
  const itemInMemory = testStorage()
    ? window.localStorage.getItem(key)
    : storageInMemory[key];
  return itemInMemory == null ? false : itemInMemory;
}

XF.Feature = (function Feature() {
  function hasTouchevents() {
    let supportTouch = getItemFromLocal("supportTouch");
    if (supportTouch !== false) {
      return supportTouch === "true";
    }

    supportTouch =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch);

    setItemInLocal("supportTouch", JSON.stringify(supportTouch));

    return supportTouch;
  }

  function hasPassiveeventlisteners() {
    const supportPassiveEventListeners = getItemFromLocal(
      "supportPassiveEventListeners"
    );
    if (supportPassiveEventListeners !== false) {
      return supportPassiveEventListeners === "true";
    }

    let passiveEventListeners = false;

    try {
      const opts = Object.defineProperty({}, "passive", {
        get() {
          passiveEventListeners = true;
        },
      });
      window.addEventListener("test", noop, opts);
      window.removeEventListener("test", noop, opts);
    } catch (e) {
      console.error(e);
    }

    setItemInLocal(
      "supportPassiveEventListeners",
      JSON.stringify(passiveEventListeners)
    );

    return passiveEventListeners;
  }

  function hasHiddenscroll() {
    const supportHiddenscroll = getItemFromLocal("supportHiddenscroll");
    if (supportHiddenscroll !== false) {
      return supportHiddenscroll === "true";
    }

    let { body } = document;
    let fake = false;

    if (!body) {
      body = document.createElement("body");
      document.body = body;
      fake = true;
    }

    const div = document.createElement("div");
    div.style.width = "100px";
    div.style.height = "100px";
    div.style.overflow = "scroll";
    div.style.position = "absolute";
    div.style.top = "-9999px";

    body.appendChild(div);

    const hiddenscroll = div.offsetWidth === div.clientWidth;

    if (fake) {
      body.parentNode.removeChild(body);
    } else {
      div.parentNode.removeChild(div);
    }

    setItemInLocal("supportHiddenscroll", JSON.stringify(hiddenscroll));

    return hiddenscroll;
  }

  const testResults: { [key: string]: unknown } = {};
  const checkedTest: { [key: string]: true | undefined } = {};
  let firstRun = true;

  function init() {
    if (firstRun) {
      docEl.className = docEl.className.replace(
        /(^|\s)has-no-js($|\s)/,
        "$1has-js$2"
      );
      firstRun = false;
    }
  }

  function runTest(name: string, result: unknown) {
    if (!checkedTest[name]) {
      testResults[name] = result;
      checkedTest[name] = true;
      docEl.className += ` has-${!testResults[name] ? "no-" : ""}${name}`;
    }
  }

  function has(name: string) {
    switch (name) {
      case "touchevents":
        runTest(name, hasTouchevents());
        break;
      case "hiddenscroll":
        runTest(name, hasHiddenscroll());
        break;
      case "passiveeventlisteners":
        runTest(name, hasPassiveeventlisteners());
        break;
      default:
        break;
    }

    if (typeof testResults[name] === "undefined") {
      console.error(`Asked for unknown test results: ${name}`);
      return false;
    }
    return testResults[name];
  }

  return {
    init,
    has,
    hasPassiveeventlisteners,
    hasTouchevents,
    hasHiddenscroll,
  };
})();

function calculateBrowserObj() {
  const savedBrowserOriginal = getItemFromLocal("browserOriginal");

  if (XF.browserOriginal) {
    return XF.browserOriginal;
  }
  if (savedBrowserOriginal !== false) {
    XF.browserOriginal = JSON.parse(savedBrowserOriginal);
    return XF.browserOriginal;
  }

  const ua = navigator.userAgent.toLowerCase();
  let match: string[] | null = /trident\/.*rv:([0-9.]+)/.exec(ua);
  let browser: (typeof XF)["browserOriginal"];
  if (match) {
    browser = {
      browser: "msie",
      version: parseFloat(match[1]),
    };
  } else {
    // this is different regexes as we need the particular order
    match =
      /(msie)[ /]([0-9.]+)/.exec(ua) ||
      /(edge)[ /]([0-9.]+)/.exec(ua) ||
      /(chrome)[ /]([0-9.]+)/.exec(ua) ||
      /(webkit)[ /]([0-9.]+)/.exec(ua) ||
      /(opera)(?:.*version|)[ /]([0-9.]+)/.exec(ua) ||
      (ua.indexOf("compatible") < 0 &&
        /(mozilla)(?:.*? rv:([0-9.]+)|)/.exec(ua)) ||
      [];

    if (match[1] === "webkit" && ua.indexOf("safari")) {
      const safariMatch = /version[ /]([0-9.]+)/.exec(ua);
      if (safariMatch) {
        match = [match[0], "safari", safariMatch[1]];
      }
    }

    browser = {
      browser: match[1] || "",
      version: parseFloat(match[2]) || 0,
    };
  }

  if (browser.browser) {
    browser[browser.browser] = true;
  }

  let os = "";
  let osVersion = null;
  let osMatch;

  if (/(ipad|iphone|ipod)/.test(ua)) {
    os = "ios";
    osMatch = /os ([0-9_]+)/.exec(ua);
    if (osMatch) {
      osVersion = parseFloat(osMatch[1].replace("_", "."));
    }
  } else {
    osMatch = /android[ /]([0-9.]+)/.exec(ua);
    if (osMatch) {
      os = "android";
      osVersion = parseFloat(osMatch[1]);
    } else if (/windows /.test(ua)) {
      os = "windows";
    } else if (/linux/.test(ua)) {
      os = "linux";
    } else if (/mac os/.test(ua)) {
      os = "mac";
    }
  }

  browser.os = os;
  browser.osVersion = osVersion;
  if (os) {
    browser[os] = true;
  }

  XF.browserOriginal = browser;
  setItemInLocal("browserOriginal", JSON.stringify(browser));

  return XF.browserOriginal;
}

XF.Feature.init();

Object.defineProperty(XF, "browser", {
  get() {
    return calculateBrowserObj();
  },
});
